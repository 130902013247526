.tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 6px 15px 7px 15px;
}

.tooltip-title {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1px;
}

.tooltip-value {
  font-weight: 800;
  font-size: 12px;
}
