@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.filter-list_item {
  @apply flex items-center min-h-[50px] text-[14px];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-decoration__underline--mid-layer::after,
.text-decoration__underline--semibold::after,
.text-decoration__underline--bold::after {
  content: '';
  background-color: #ffe27c;
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -8%;
  z-index: -1;
}

/* bold 스타일 */
.text-decoration__underline--bold::after {
  height: 11px;
}

/* semibold 스타일 */
.text-decoration__underline--semibold::after {
  height: 9px;
}

/* mid-layer 스타일 */
.text-decoration__underline--mid-layer::after {
  height: 10px;
  background-color: #fdd853;
}

.text-decoration__underline--mid-layer,
.text-decoration__underline--semibold,
.text-decoration__underline--bold {
  position: relative;
}

/* 모든 .ion-page 스타일 비활성화 */
.ion-page {
  justify-content: start !important;
}

@screen tablet-sm {
  .ion-page {
    padding-bottom: calc(var(--ion-safe-area-bottom) - 45px) !important;
  }
}

/* color 지정하지 않으면 테마에 따라감 */
.toast {
  --border-radius: 6px;
  --background: rgb(0, 0, 0, 0.8);
  transform: translateY(-110px) !important;
  --box-shadow: none;
  --color: white;
  font-size: 15px;
}

.toast-error {
  --border-radius: 6px;
  --background: rgb(0, 0, 0, 0.8);
  transform: translateY(-110px) !important;
  --box-shadow: none;
  --color: red;
  font-size: 15px;
}
