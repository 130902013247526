/* 기본 스타일 제거 및 변경 */
.react-datepicker {
  border-width: 0;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
  min-width: 320px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__header {
  background-color: transparent;
  border: none;
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__day {
  margin: 0;
}
.react-datepicker__month-text--keyboard-selected {
  background-color: transparent;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

/* 헤더 스타일 */
.react-datepicker__header--custom {
  padding: 0 9px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 요일 스타일 */
.react-datepicker__day {
  width: 47px;
  height: 47px;
}
.react-datepicker__day--disabled {
  color: #a1a1a1;
}
.react-datepicker__day > .day-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-datepicker__day:hover {
  border-radius: 100%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: transparent;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--selected {
  background-color: black;
  border-radius: 100%;
  color: white;
  font-weight: 800;
}

.react-datepicker__day--weekend,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: transparent;
}

/* 요일 라벨 제거 */
.react-datepicker__day-names {
  display: none;
}

/* Outsize-month에 해당하는 일 스타일 */
.react-datepicker__day--outside-month {
  color: #a1a1a1;
}
.react-datepicker__day--outside-month.react-datepicker__day--saturday {
  color: #add9ff;
}
.react-datepicker__day--outside-month.react-datepicker__day--sunday {
  color: #ffb7b2;
}

/* Weekly Range 선택 시 스타일 */
.react-datepicker__day--in-range.react-datepicker__day:hover,
.react-datepicker__day--in-range {
  background-color: black;
  border-radius: 0;
  color: white;
  font-weight: 800;
}

.react-datepicker__day--range-start.react-datepicker__day:hover,
.react-datepicker__day--range-start {
  background-color: black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;
}

.react-datepicker__day--range-end.react-datepicker__day:hover,
.react-datepicker__day--range-end {
  background-color: black;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
}

.react-datepicker__day--in-range.react-datepicker__day--keyboard-selected:hover {
  background-color: black;
}

/* Sat/Sun 스타일 */
.react-datepicker__day--saturday {
  color: #0085fa;
}
.react-datepicker__day--sunday {
  color: #ff5f55;
}
.react-datepicker__day--saturday--disabled {
  color: #add9ff;
}
.react-datepicker__day--sunday--disabled {
  color: #ffb7b2;
}

/* Month 선택 스타일 */
.react-datepicker__month .react-datepicker__month-text {
  width: 100px;
  height: 45px;
  margin: 10px 5px;
  font-weight: 500;
  font-size: 15px;
}

/* TODO: 가운데 정렬 */
@media (max-width: 330px) {
  .react-datepicker__month .react-datepicker__month-text {
    width: 90px;
    height: 45px;
    margin: 10px 5px;
    font-weight: 500;
    font-size: 15px;
  }
}

.react-datepicker__month-text > .month-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .react-datepicker__month-text--keyboard-selected, */
.react-datepicker__month-text--selected.react-datepicker__month-text:hover,
.react-datepicker__month-text--selected {
  background-color: black;
  color: white;
  font-weight: 800;
}
