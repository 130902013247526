ion-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  --height: fit-content;
  --border-radius: 0px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#sheet-modal::part(content) {
  margin-top: 30px;
  height: calc(100% - 30px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

ion-modal#custom-modal::part(content) {
  height: 360px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

ion-modal#custom-modal.nested-dialog {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.25;
}

ion-modal#custom-modal.edited-goal-alert {
  justify-content: center;
  --border-radius: 15px;
  --box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

ion-modal#custom-modal.edited-goal-alert::part(content) {
  max-width: 280px;
  max-height: 210px;
}

ion-modal#custom-modal.date-range-picker-modal::part(content) {
  height: 450px;
  width: 100%;
}

@screen tablet-sm {
  ion-modal {
    --border-radius: 15px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    justify-content: center;
  }

  ion-modal#sheet-modal::part(content) {
    margin-top: 0px;
    height: 71%;
    width: 90%;
  }

  ion-modal#custom-modal::part(content) {
    max-width: 362px;
    max-height: 360px;
    padding-left: 0px;
    padding-right: 0px;
    height: 45%;
    width: 52%;
  }

  ion-modal#custom-modal.datetime-modal::part(content) {
    max-width: 392px;
    max-height: 370px;
    height: 55%;
    width: 60%;
  }

  ion-modal#custom-modal.picker-modal::part(content) {
    max-width: 280px;
    max-height: 370px;
    min-height: 370px;
    height: 370px;
    width: 60%;
  }

  ion-modal#custom-modal.date-range-picker-modal::part(content) {
    max-width: 370px;
    max-height: 465px;
    min-height: 465px;
    height: 465px;
    width: 69%;
  }
}
