ion-datetime {
  margin-top: 10px;
  width: 100%;
  background-color: transparent;
  --wheel-highlight-background: rgba(253, 202, 83, 0.2);
  --wheel-fade-background-rgb: none;
}

ion-datetime::part(wheel-item) {
  font-size: 14px;
  /* height로 지정하면 change 무한 스크롤 발생하여 margin으로 지정함 */
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 220px;
  text-align: center;
  color: #a1a1a1;
}

ion-datetime::part(wheel-item active) {
  color: black;
}

ion-datetime#app-datetime-presentation-date::part(wheel-item) {
  min-width: 100%;
}

ion-datetime#app-date-range-picker::part(wheel-item) {
  min-width: 100%;
}

ion-datetime::part(month-year-button) {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

ion-datetime::part(calendar-day) {
  font-size: 14px;
  font-weight: 500;
}

ion-datetime::part(calendar-day today) {
  color: black;
  border-color: black;
}

ion-datetime::part(calendar-day active):focus,
ion-datetime::part(calendar-day active) {
  color: white;
  background-color: black;
  border: 0;
  font-weight: 800;
  box-shadow: none;
}

@screen tablet-sm {
  ion-datetime::part(wheel-item) {
    font-size: 16px;
  }
}
