ion-picker {
  --highlight-background: rgba(253, 202, 83, 0.2);
  --fade-background-rgb: none;
}

ion-picker-column-option {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 20px;
  min-width: 140px;
  text-align: center;
  color: #a1a1a1;
}

ion-picker-column-option.left {
  padding-left: 30px;
}

ion-picker-column-option.right {
  padding-right: 30px;
}

ion-picker-column-option.option-active {
  color: black;
}

@screen tablet-sm {
  ion-picker-column-option {
    font-size: 16px;
    min-width: 100px;
    text-align: center;
  }

  ion-picker-column-option.left {
    padding-left: 40px;
  }

  ion-picker-column-option.right {
    padding-right: 20px;
  }
}
